import Vue from 'vue'
import Vuex, { Store, StoreOptions } from 'vuex';
import { Context } from '../../../core/src/app';

Vue.use(Vuex)

interface State {
    Context: Context;
}

export function newStore(context: Context): StoreOptions<State> {
    return {
        state: {
            Context: context,
        },
        mutations: {
            setContext(state, ctx: Context) {
                state.Context = ctx
            },
        },
    }
}

export function setContext(store: Store<State>, ctx: Context) {
    store.commit('setContext', ctx)
}

export function getContext(store: Store<State>): Context {
    return store.state.Context;
}
