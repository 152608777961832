
























import Vue from 'vue';
import { Context } from '../../core/src/app';
import { Session, getSession } from '../../core/src/auth';
import { getTime } from '../../core/src/service';

export default Vue.extend({
  name: 'App',
  computed: {
    context(): any {
      return this.$store.state.Context;
    },
  },
  data() {
    return { 
      session: {},
      time: {},
    }
  },
  methods: {
    async onClickGetSession() {
      let session = await getSession(this.context);
      this.session = session;
    },
    async onClickGetTime() {
      let time = await getTime(this.context);
      this.time = time;
    }
  },
});
