export class EventBus {
    private subscribers: any;

    constructor() {
        this.subscribers = {};
    }

    public unsubscribe(event: string, subscriber: (response: any) => void) {
        const handlers = this.subscribers[event] as any;
        if (handlers) {
            for(let i = handlers.length - 1; i >= 0; i--) {
                if (handlers[i] === subscriber) {
                    handlers.splice(i, 1);
                }
            }
        }
    }

    public subscribe(event: string, handler: (...args: any[]) => void) {
        let handlers = this.subscribers[event] as any;
        if (!handlers) {
            handlers = [];
        }
        handlers.push(handler);
        this.subscribers[event] = handlers;
    }

    public publish(event: string, payload: any): void {
        const handlers = this.subscribers[event] as any;
        if (handlers) {
            handlers.forEach((x: any) => x.apply(this, payload));
        }
    }

}
